<template>
  <v-container
    fluid
    class="pa-4">
    <v-card
      v-if="items.length > 0"
      ref="release-note-list"
      class="pa-4">
      <h1 class="font-weight-light">
        Change Logs (Release Note)
      </h1>
      <v-timeline dense>
        <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          :color="item.color"
          dense
        >
          <div class="py-4">
            <h2
              :class="'headline font-weight-light'"
              :style="`color: ${item.color};`">
              {{ item.title }}
              <v-chip
                class="mb-1"
                :color="getReleaseNoteTypeColor(item.type)"
                text-color="white"
                small>
                {{ item.type }}
              </v-chip>
            </h2>
            <span class="text-block caption"> {{ item.dateString }}</span>
            <div class="mt-4 text-block">
              {{ item.detail }}
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>

      <p
        v-if="showLoadMore"
        class="text-center">
        <a @click="getReleaseNote">More </a>
      </p>
    </v-card>
  </v-container>
</template>

<script>
import ReleaseNoteProvider from '@/resources/ReleaseNoteProvider'
import { getRole } from '@/assets/js/Authentication'

const ReleaseNoteService = new ReleaseNoteProvider()
export default {
  name: 'Home',
  data () {
    return {
      types: [
        {
          name: 'FEATURE',
          value: 'FEATURE',
          color: '#4CAF50'
        },
        {
          name: 'CHANGED',
          value: 'CHANGED',
          color: '#9C27B0'
        },
        {
          name: 'FIXED',
          value: 'FIXED',
          color: '#E91E63'
        },
        {
          name: 'ISSUE',
          value: 'ISSUE',
          color: '#F44336'
        },
        {
          name: 'ANNOUNCEMENT',
          value: 'ANNOUNCEMENT',
          color: '#03A9F4'
        },
        {
          name: 'NOTE',
          value: 'NOTE',
          color: '#607D8B'
        }
      ],
      page: 0,
      total: 0,
      items: [],
      loading: false
    }
  },
  computed: {
    showLoadMore () {
      return this.total > this.items.length
    }
  },
  async created () {
    await this.getReleaseNote()
  },
  methods: {
    getReleaseNoteTypeColor (type) {
      const colors = {}
      this.types.forEach((t) => { colors[t.name] = t.color })

      return colors[type] || '#607D8B'
    },
    async getReleaseNote () {
      try {
        this.loading = true
        this.page += 1
        const role = getRole()
        const { data } = await ReleaseNoteService.getReleaseNote({
          role,
          sortBy: 'dateString',
          sortOrder: 'desc',
          page: this.page,
          limit: 25
        })
        this.total = data.total
        this.items = [...this.items, ...data.results.map((re) => ({
          ...re,
          color: this.getReleaseNoteTypeColor(re.type)
        }))]
      } catch (error) {
        console.error('getReleaseNote: ', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
.text-block {
  white-space: pre;
  color: #6d6d6d;
}
</style>
